import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, MainHeading } from "../components/styled/Styled"
import WorkshopList from "../components/lists/WorkshopList"

const WorkshopPage = () => {
  return (
    <Layout>
      <SEO title="Workshops" />
      <Container>
        <MainHeading style={{ textAlign: "center" }}>WORKSHOPS</MainHeading>
        <WorkshopList />
      </Container>
    </Layout>
  )
}

export default WorkshopPage
